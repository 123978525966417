import React from "react";
import Footer from "../../main/common/Footer";
import Header from "../../main/common/Header";
import { Outlet } from "react-router-dom";

const LandingPageLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
export default LandingPageLayout;
