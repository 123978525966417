import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../../presentation/main/home/HomePage";
import LandingPageLayout from "../../presentation/shared/layouts/LandingPageLayout";
import WhatIsBorga from "../../presentation/main/what_is_borga/WhatIsBorga";
import { Privacy } from "../../presentation/main/privacy/Privacy";
import { Faqs } from "../../presentation/main/faqs/Faqs";
import { TermsAndConditions } from "../../presentation/main/ts-and-cs/TermsAndConditions";
import Verification from "../../presentation/shared/email-pages";
import { FAQ } from "../../presentation/main/faqs/FAQ";
import { PageNotFound400 } from "../../presentation/main/page-not-found/PageNotFound";

const AppRouting = () => {
  return (
    <Routes>
      {/* Landing area */}
      <Route path="/" element={<LandingPageLayout />}>
        <Route index element={<Navigate replace to="home" />} />
        <Route path="home" element={<HomePage />} />
        <Route path="what_is_borga" element={<WhatIsBorga />} />
        <Route path="privacy" element={<Privacy/>} />
        <Route path="faqs" element={<Faqs/>} />
        <Route path="terms-conditions" element={<TermsAndConditions/>} />
        
      </Route>

      {/* Not found */}
      {/* <Route path="/verification_expired" element={<VerificationExpired/>}></Route> */}
      <Route path="/email_verification" element={<Verification/>}></Route>
      <Route path="FAQ" element={<FAQ/>} />
      <Route path="*" element={<PageNotFound400/>}></Route>
    </Routes>
  );
};

export default AppRouting;
