import React from "react";
import { Card, Image } from "react-bootstrap";
import { LogoDark, Failed } from "../../../assets/images";
import Lottie from "lottie-react";
import Footer from "../../main/common/Footer";

export const VerificationExpired = () => {
  return (
    <>
    <section className="bg-light w-100 min-vh-100">
      <Card className="email-verification-card border-0 position-absolute top-50 start-50 translate-middle" >
        <Card.Body>
          <div className="text-center">
            <Image src={LogoDark} style={{width:"60px",height:"46px"}}/>
          </div>
          <div className="text-center fs-1 mt-5">Verification link expired!</div>
          <div className="d-flex justify-content-center" style={{marginTop:"-15%"}}> 
            <Lottie
              animationData={Failed}
              loop={true}
              autoplay={true}
              style={{ width: "200px", height: "300px" }}
            />
          </div>
          <div className="text-center msg mx-5" style={{fontSize:"12px",marginTop:"-10%"}}>
          Looks like the verification email has expired. Try again from the Borga app.

          </div>

          <div className="text-center mb-5 " style={{fontSize:"12px"}}>Thank you for choosing Borga Money.</div>
        </Card.Body>
      </Card>

   
    </section>
    <Footer/>
    </>
    
  );
};
