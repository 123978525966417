import { ContactUs } from "./ContactUs";
import { FAQ } from "./FAQ";

export const Faqs = () => {
  return (
    <>
      <ContactUs />
      <FAQ />
    </>
  );
};
