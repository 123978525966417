
import {Card, Image } from "react-bootstrap";
import { LogoDark, Successful } from "../../../assets/images";
import Lottie from "lottie-react";



export const EmailVerified = () => {
  return (
    <>
    <section className="bg-light w-100 min-vh-100">
      <Card className="email-verification-card border-0 position-absolute top-50 start-50 translate-middle" style={{width:"450px"}}>
        <Card.Body>
          <div className="text-center">
            <Image src={LogoDark} style={{width:"60px",height:"46px"}}/>
          </div>
          <div className="text-center fs-1 mt-5">Email verified</div>
          <div className="d-flex justify-content-center" style={{marginTop:"-15%"}}> 
            <Lottie
              animationData={Successful}
              loop={true}
              autoplay={true}
              style={{ width: "200px", height: "300px" }}
            />
          </div>
          {/* <div className="text-center msg " style={{fontSize:"12px",marginTop:"-15%"}}>
          Thank you, your email has been verified. Your account is now active. Please use the link below to login to your account.
          </div> */}
          
          <div className="text-center mb-5" style={{fontSize:"12px",marginTop:"-15%"}}>Thank you for choosing Borga Money.</div>
        </Card.Body>
      </Card>
    </section>
    </>
    
  );
};
