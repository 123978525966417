import React from "react";
import { Card, Col, Row } from "react-bootstrap";


export const WhatPeopleSayAboutUs = () => {
  const feedbacks = [
    {
      icons: "",
      title: "Verify Receiver Details. 🔍👀",
      body: "Always double-check recipient information before sending money. A small confirmation step can save you from big remittance mishaps.",
    },
    {
      icons: "",
      title: "Stay Wary of Suspicious Requests! 🕵‍♂🔍",
      body: "If someone asks for money unexpectedly or urgently, take a moment to investigate. Fraudsters use urgency to catch you off guard, so stay vigilant.",
    },
    {
      icons: "",
      title: "Stay Alert! 🛡🔒",
      body: "Never expose your PIN or passwords to anyone. ",
    },
  ];
  return (
    <div className="what-people-say-about-us-section">
      <div className="what-people-say-about-us-section-header text-white  fs-3">
        Tips for transaction safety.
      </div>
      <div className="feedback-cards-scroll-container">
        <Row className="d-flex flex-nowrap feedback-card-row-container align-items-stretch gx-5 mb-5">
          {feedbacks.map((data, key) => (
            <Col md={4} key={key} className="feedback-card-col">
              <Card className="feedback-cards border-0 h-100 ">
                <Card.Body className="feedback-cards-body pb-4 px-4 fs-5 fw-light d-flex flex-column">
                  <div className="fs-4 mt-3 fw-bold">{data.title}</div>
                  <div className="mt-3 pb-3">
                    {data.body}
                  </div>
                </Card.Body>
                {/* <Card.Footer className="border-0 py-4">
                  <div className="border-divider my-3"></div>
                  <div className="d-flex align-items-center">
                   
                    <div className="mx-3 fw-bold fs-5">{data.name}</div>
                  </div>
                </Card.Footer> */}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
