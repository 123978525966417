// import { BsChatDots } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { PiMapPinLight } from "react-icons/pi";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";


export const FaqData = [
  {
    num: "0",
    question: "What is Borga? ",
    answer:
      "Borga is a remittance app that allows users within the UK to seamlessly send funds, pay bills, and directly pay for goods and services to recipients in Ghana and other African countries. ",
  },
  {
    num: "1",
    question: "How do I download the app? ",
    answer: "Download on the Google Play Store or the Apple App Store. ",
  },
  {
    num: "2",
    question: "How do I sign up? ",
    answer:
      "To sign up, you must provide a valid email address, a phone number and a 4-digit PIN. ",
  },
  {
    num: "3",
    question: "What is an OTP? ",
    answer: `An OTP, short for "One-Time Password," is a confidential 6-digit code intended for single-use purposes. Think of it as a unique, temporary key that enhances the security of your online account. It ensures your access is granted solely upon entering the correct code, adding an extra layer of protection to your account. `,
  },
  {
    num: "4",
    question: "What happens if my OTP expires, or I do not receive it? ",
    answer: `If your initial OTP code has expired or has not been received, you have the option to request another OTP. To initiate this process, locate the "Resend OTP" button conveniently positioned on the OTP screen. This feature enables you to secure a fresh one-time password and proceed with your verification process. `,
  },
  {
    num: "5",
    question: "What is a PIN? ",
    answer:
      "A PIN functions as a discreet code that you establish to unlock Borga or validate a transaction. Its purpose is to verify your identity as the individual approving an action on the Borga platform. ",
  },
  {
    num: "6",
    question: "What happens if I forget my PIN? ",
    answer:
      ` You can click on “Reset" on the Sign In page to change your password. This action will guide you through the process of updating your password.  `,
  },
  {
    num: "7",
    question: "How do I sign in? ",
    answer:
      "To sign in, you'll need to use your registered phone number along with your PIN for authentication. ",
  },
  {
    num: "8",
    question: "How do I verify my email? ",
    answer:
      "To verify your email, a link will be dispatched to your provided email address. Simply tap on the link to complete the verification of your email address.",
  },
  {
    num: "9",
    question: "How do I verify my account? ",
    answer:
      "To verify your account, specific essential details will be requested. The details required are: First name, Middle name, Last name, Residential address, Country of birth, Income range, Employment Status, Source of funds and ID details. Once these details have been reviewed and validated for accuracy, your account will receive official verification.  ",
  },
  {
    num: "10",
    question: "How do I send money on Borga? ",
    answer: (
      <div>
        You can send money in 4 simple steps.
        <ul>
          <li>Input the desired transfer amount. </li>
          <li> Select the reason for the transfer. </li>
          <li>    Select where the recipient will receive funds, i.e. mobile money or bank. </li>
          <li>
          Complete payment via card. 
          </li>
          {/* <li>
            Indicate your preferred payment method, either via card or
            bank.
          </li> */}
        </ul>
        <p className="fw-bold">
          {" "}
          Please note that you must be a verified user to send money on Borga.
        </p>
      </div>
    ),
  },
  {
    num: "11",
    question: "How many countries can I send money to? ",
    answer:
      "Presently, you have the convenience of sending to Ghana. Nevertheless, we are in the process of expanding our services to more African countries. ",
  },
  {
    num: "12",
    question:
      "How will I know the exchange rate being applied during a transaction? ",
    answer:
      "The exchange rate will become apparent when you initiate the transaction process. ",
  },
  {
    num: "13",
    question: "Which account will I send money from? ",
    answer:
      "You can send money using your debit or credit card.",
  },
  {
    num: "14",
    question: "Which type of accounts can I send money to? ",
    answer: "Mobile money accounts and bank accounts. ",
  },
  {
    num: "15",
    question: "Is there a minimum amount I can send? ",
    answer: "Yes. The minimum amount you can send is £1.00.",
  },
  {
    num: "16",
    question: "Is there a limit on the amount of money I can send? ",
    answer: "Yes. There is a £2000 daily limit. ",
  },
  {
    num: "17",
    question: "Are there any fees for sending money? ",
    answer:
      "While the service itself is free, please be aware that standard fees from your card payment processor might be applicable. ",
  },
  {
    num: "18",
    question: "How do I pay utility bills in Ghana using Borga? ",
    answer: (
      <div>
        To pay utility bills in Ghana, kindly follow the steps.
        <ul>
          <li>Tap Pay Bill on the home page. </li>
          <li>Select the specific bill you want to pay for. </li>
          <li>Enter the bill account. </li>
          <li>
          Proceed to payment. 
          </li>
          <li>Enter card details. </li>
          {/* <li>Opt for your preferred payment method: bank or card.</li> */}
        </ul>
      </div>
    ),
  },
  {
    num: "19",
    question: "Which utility bills can I Pay for in Ghana using Borga? ",
    answer: (
      <div>
        <ul>
          <li>Water Bill </li>
          <li>GoTV</li>
          <li>DSTV </li>
        </ul>
      </div>
    ),
  },
  {
    num: "20",
    question: "Is there a limit on the amount I can pay for utility bills? ",
    answer: "Yes. A daily limit of £500 is applicable for utility bills. ",
  },
  {
    num: "21",
    question:
      "How do I pay for airtime and data bundles in Ghana using Borga? ",
    answer: (
      <div>
        <ul>
          <li>
          Choose the network for which you're purchasing airtime or data. 
          </li>
          <li>Input the recipient's phone number. </li>
          <li>
          The amount will be automatically calculated based on the chosen data, however for airtime you're required to specify the amount. 
          </li>
          <li>Complete payment with your card. </li>
        </ul>
      </div>
    ),
  },
  {
    num: "22",
    question:
      "Is there a limit on the amount of airtime or data I can buy in Ghana using Borga? ",
    answer: "Yes. A daily limit of £500 is applicable for airtime and data. ",
  },
  {
    num: "23",
    question: "Are there any fees for buying airtime or data? ",
    answer:
      "While the service itself is free, it's important to note that standard fees from 	your card payment processor may apply. ",
  },
  {
    num: "24",
    question: "Which merchants can I pay in Ghana using Borga? ",
    answer: (
      <div>
        The permissible categories are:
        <ul>
          <li>Education (Schools)</li>
          <li> Health (Medicines, Health Bills) </li>
          <li>Construction</li>
        </ul>
      </div>
    ),
  },
  {
    num: "25",
    question:
      "Is there a limit on the amount of money I can pay a merchant in Ghana using Borga? ",
    answer: "Yes. A daily limit of £5000 is applicable for merchant payment. ",
  },
  {
    num: "26",
    question: "Are there any fees for paying a merchant? ",
    answer:
      "While the service itself is free, it's important to note that standard fees from 	your card payment processor may apply. ",
  },
  {
    num: "27",
    question:
      "In case I buy a physical item, how do I confirm it has been delivered? ",
    answer:
      "After you've confirmed the receipt of the item from your delivery contact, you can then proceed to verify the delivery of the item within the application. ",
  },
  {
    num: "28",
    question: "How long does it take for an item to be delivered? ",
    answer: "Deliveries may take up to 72 hours (about 3 days). ",
  },
  {
    num: "29",
    question:
      "What happens if the item I purchased is not delivered or the wrong item is delivered? ",
    answer:
      "If you encounter such an issue, you have the option to raise a dispute. The Borga team will diligently investigate the matter to ensure a prompt and precise resolution. ",
  },
  {
    num: "30",
    question: "How do I view my past disputes? ",
    answer:
      "You can navigate to the support tab, where you will discover a dedicated section designed to aid you in tracking your disputes. ",
  },
  {
    num: "31",
    question: "How do I view my transactions? ",
    answer:
      "You can find a dedicated transactions tab that will display all transactions. ",
  },
  {
    num: "32",
    question: "How do I view my profile? ",
    answer: `Access the settings tab to locate a dedicated section labeled "Account Information." This section is designed to consolidate all your personal details in one place. `,
  },
  {
    num: "33",
    question: "How do I get customer support on Borga?  ",
    answer: "Through the support section within the application. ",
  },
];

export const FaqCardData = [
  {
    icon: <CiMail className="fs-3" />,
    title: "Send us a message",
    body: "We are waiting to answer your questions.",
    link:`mailto:support@borga.money`,
    info: "support@borga.money",
  },
  // {
  //   icon: <BsChatDots className="fs-3" />,
  //   title: "Chat support",
  //   body: "We're here to help.",
  //   info: "Say Hi on telegram",
  // },
  {
    icon: <PiMapPinLight className="fs-3" />,
    title: "Visit us",
    link:`https://www.google.com/search?q=30+Churchill+Place+Canary+Wharf.%2C+Churchill+Place%2C+London%2C+England%2C+E14+5RE&rlz=1C1GCEU_enGH1038GH1040&oq=30+Churchill+Place+Canary+Wharf.%2C+Churchill+Place%2C+London%2C+England%2C+E14+5RE&aqs=chrome..69i57.410j0j7&sourceid=chrome&ie=UTF-8`,
    body: "443 Streatham High Rd, London SW16 3PH.",
    info: "View on Google Maps",
  },
  {
    icon: <IoCallOutline className="fs-3" />,
    title: "Call us",
    body: "Mon-Fri from 8am to 5pm",
    // info: "phone :  +44 20 33690013 ",
    info: <div><p>Phone :  +44 20 33690013 </p> <p> Office contact : 02033409637</p></div>
  },
  {
    icon: <MdOutlineDeleteOutline  className="fs-3 text-dark" />,
    title: "Delete account",
    body: "Request for your account to be deleted.",
    // info: "phone :  +44 20 33690013 ",
    info: <div><a href="mailto:support@borga.money" className="text-black">support@borga.money</a></div>
  },
];
