import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Google, Apple } from "../../../assets/images/index";
import Container from "react-bootstrap/Container";
import { Bg1 } from "../../../assets/images/index";

export const EasyPay = () => {
  return (
    <div>
      <Container>
        <div className="on-screen-type-image-display">{/* . */}</div>
        <Row>
          <Col >
            <Row className="alternating-image-section-row ">
              <Col md={12} className="alternating-image-section-col py-5">
                <div className=" alternating-image-section-text" id="home">
                  Easy payments to merchants
                </div>
                <div className=" alternating-image-section-text">
                  back home in Africa!
                </div>
                <div className="fw-light sub-text">
                  Make direct payments to your favourite merchants back home in
                  Africa.
                </div>
              </Col>
              <Col className="d-flex brand-logo-container">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=money.borga&hl=en_US&pli=1">
                    <img
                      src={Google}
                      alt="googleplay"
                      className="brand-logo"
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>
                <div>
                  <a href="https://apps.apple.com/us/app/borga-money/id6463721868">
                    <img
                      src={Apple}
                      alt="appstore"
                      className="mx-2 brand-logo"
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="d-none d-xl-block">
            <div className="alternating-image-section">
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
