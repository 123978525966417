import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
  MastercardColor,
  PayBills,
  PayMerchant,
  SendMoney,
  VisaColor,
} from "../../../assets/images/index";
import { LuShoppingBag } from "react-icons/lu";
import { IoMdArrowForward } from "react-icons/io";
import { HiOutlineClipboardList } from "react-icons/hi";
import Container from 'react-bootstrap/Container';

export const WhyBorga = () => {
  const cardData = [
    {
      icons: <LuShoppingBag className="text-black  icon-display fs-3" />,
      title: "Pay merchants",
      body: "Make direct payments to your favourite merchants back home in Africa.",
      image: `${PayMerchant}`,
      logo: [
        { img: VisaColor, width: 45, height: 27 },
        { img: MastercardColor, width: 25, height: 20 },
      ],
    },
    {
      icons: <IoMdArrowForward className="text-black  icon-display fs-3" />,
      title: "Send money",
      body: "Send love across borders with just a tap! Send money to your loved ones back home in Africa, quickly and securely.",
      image: `${PayBills}`,
      logo: [
        { img: VisaColor, width: 45, height: 27 },
        { img: MastercardColor, width: 25, height: 20 },
      ],
    },
    {
      icons: (
        <HiOutlineClipboardList className="text-black  icon-display fs-3" />
      ),
      title: "Pay bills",
      body: "Pay the bills of your loved ones back home in Africa.",
      image: `${SendMoney}`,
      logo: [
        { img: VisaColor,width: 45, height: 27 },
        { img: MastercardColor, width: 25, height: 20 },
      ],
    },
  ];

  return (
    <Container fluid className="why-borga-text container-xxl">
      <div className="container fw-bold fs-1 mb-4">Why Borga ? </div>
      <Row className="d-flex flex-nowrap why-borga-row-container ">
        {cardData.map((data, key) => (
          <Col md={4} key={key} className="why-borga-col-container">
            <Card className=" why-borga-card border-0 p-0 m-0">
              <Card.Img src={data.image} className="why-borga-card-images" />
              <Card.Body className="bg-primary card-body">
                <div className="icon-images-here bg-info rounded-circle icon-bg-container border border-black">
                  {data.icons}
                </div>
                <Card.Title className="fw-bold text-white mt-4">
                  <div className="d-flex justify-content-end">
                    <div className=" payment-options ">
                      {data.logo.map((items, key) => (
                        <div key={key} className="mx-1 py-1">
                          <img
                            src={items.img}
                            alt="logo"
                            width={items.width}
                            height={items.height}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {data.title}
                </Card.Title>
                <div className="why-borga-body-text">
                  <div className="text-white fw-light  ">{data.body}</div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
