export const tutData = [
    {
      sendmoney: [
        {
          id: 1,
          title: "Sign Up for free.",
          body: " Sign up for a free account using just your phone number and email. It's that simple!",
        },
        {
          id: 2,
          title: "Enter the amount you want to send.",
          body: "Simply input the amount you wish to send and select the recipient's country. Borga App will instantly show you the foreign exchange rates for that destination.",
        },
        {
          id: 3,
          title: "Select channel of receipt.",
          body: "Select the recipient's preferred method to receive money - either via mobile money or directly into their bank account. ",
        },
        {
          id: 4,
          title: "Enter recipient details.",
          body: "Carefully enter the recipient's mobile money number or bank account details. Double-check for accuracy to ensure a smooth and trouble-free transfer.",
        },
        {
          id: 5,
          title: "Make Payment.",
          body: "Safely transfer funds or make payments by entering your card details.",
        },
        {
          id: 6,
          title: "Authorize Transaction.",
          body: "Provide your Borga App PIN to authenticate the transaction. This ensures that only authorized individuals can send money from your account.",
        },
      ],
    },
    {
      paymerchant: [
        {
          id: 1,
          title: "Sign Up for free.",
          body: " Sign up for a free account using just your phone number and email. It's that simple!",
        },
        {
          id: 2,
          title: "Search for your items.",
          body: "Search for the items you want to purchase and select from our vast and diverse catalog.",
        },
        {
          id: 3,
          title: "Select your merchants.",
          body: "The app will show you the best merchants to buy from based on your selected items. Pick your favorite merchant and continue to place your order. ",
        },
        {
          id: 4,
          title: "Make Payment.",
          body: "Safely transfer funds or make payments by entering your card details.",
        },
        {
          id: 5,
          title: "Authorize Transaction.",
          body: "Provide your Borga App PIN to authorize the transaction. This ensures that only you can send money from your account. Keeping your finances safe and sound!",
        },
        {
          id: 6,
          title: "Confirm Delivery.",
          body: "Stay updated on your item's delivery progress with app notifications and confirm receipt to ensure the merchant receives timely payment.",
        },
      ],
    },
    {
      paybills: [
        {
          id: 1,
          title: "Sign Up for free. ",
          body: "Sign up for a free account using just your phone number and email. It's that simple!",
        },
        {
          id: 2,
          title: "Select bill.",
          body: "Select the type of bill you want to pay provide the necessary details required to make the payment. This may include the bill account number, references, ID numbers etc.",
        },
        {
          id: 3,
          title: "Verify bill details.",
          body: "Verify the bill details on the summary page to guarantee accurate payment for the designated account with precise details",
        },
        {
          id: 4,
          title: "Make Payment.",
          body: "Safely transfer funds or make payments by entering your card details.",
        },
        {
          id: 5,
          title: "Authorize Transaction.",
          body: "Provide your Borga App PIN to authorize the transaction. This ensures that only you can send money from your account. Keeping your finances safe and sound!",
        },
        {
          id: 6,
          title: "Get Receipt.",
          body: "Upon successful payment, a receipt will promptly appear in your transactions view for your reference.",
        },
      ],
    },
  ];