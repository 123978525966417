import { Image } from "react-bootstrap";
import { LogoDark } from "../../../assets/images/index";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';


// import { useState } from "react";

function Header() {

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container className="py-4 border-bottom">
            <Navbar.Brand as={Link} to="/home#home">
              <Image src={LogoDark} width="40%" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="p-0 m-0"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-inline">
                <Nav className="justify-content-end flex-grow-1">
                  <Nav.Link eventKey="/" as={Link} to="/home#" className="">
                    Home
                  </Nav.Link>
                  <Nav.Link
                    eventKey="/what_is_borga"
                    as={Link}
                    to="/home#what-is-borga"
                    className=""
                    // onClick={(e) =>
                    //   scrollToSection(e, "what-is-borga")
                    // }
                  >
                    What is borga?
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Header;
